/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import classNames from "classnames";
import { AnimatePresence, m } from "framer-motion";
import { useAppState } from "@state";

const PageContainer = ({ children, className, path, location }) => {
  const [{ layout }] = useAppState();
  const { intentions: intentionsOpen } = layout;
  const isIntentions =
    process.env.GATSBY_CRAFT_SITE_HANDLE === "intentionCards";

  return (
    <m.main
      key={path || location?.pathname || "404"}
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 0 }}
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.5,
      }}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
        }
      }}
      className={classNames(
        className,
        "relative z-0 flex min-h-screen w-screen flex-grow flex-col",
        {
          "texture-pattern bg-viridian": !isIntentions,
        }
      )}
    >
      {isIntentions && (
        <div
          className={classNames("fixed inset-0 -z-10 h-screen w-screen", {
            "int-texture": isIntentions,
            "int-texture--up": isIntentions && !intentionsOpen,
            "int-texture--down": isIntentions && intentionsOpen,
          })}
        />
      )}
      <AnimatePresence initial>{children}</AnimatePresence>
    </m.main>
  );
};

PageContainer.defaultProps = {
  className: "",
};

export default PageContainer;
